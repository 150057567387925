import classNames from 'classnames';

import React from 'react';

import { HandleEnterUpdateDelete } from 'flip-toolkit/lib/types';
import { Flipped, Flipper, spring } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';

import { Articles } from '@ttstr/api';
import { SushiProps } from '../../../components/Sushi/Sushi';
import Maki from '../../../components/Sushi/Maki';
import Nigiri from '../../../components/Sushi/Nigiri';
import { Loading } from '../../../components/';
import { Filters } from '../../../components/Masonry/MasonryFilter';

const MasonryFilter = React.lazy(() => import('../../../components/Masonry/MasonryFilter'));

const handleEnterUpdateDelete: HandleEnterUpdateDelete = ({
  animateEnteringElements,
  animateExitingElements,
  animateFlippedElements,
  hideEnteringElements,
}) => {
  animateEnteringElements();
  animateExitingElements();
  animateFlippedElements();
  hideEnteringElements();
};

const onAppear = (el: HTMLElement /* , index: number */) => {
  el.style.zIndex = '0';
  spring({
    config: 'gentle',
    values: {
      opacity: [0, 1],
      translateY: [-10, 0],
    },
    onUpdate: ({ opacity, translateY }: any) => {
      el.style.opacity = opacity;
      el.style.transform = `translateY(${translateY}px)`;
    },
  });
};

const onExit = (el: HTMLElement, index: number, removeElement: () => void) => {
  removeElement();
  return;

  el.style.zIndex = '-1';
  spring({
    config: 'gentle',
    values: {
      opacity: [1, 0],
      scale: [1, 0.5],
      translateY: [0, 10],
    },
    onUpdate: ({ opacity, scale, translateY }: any) => {
      el.style.opacity = opacity;
      el.style.transform = `translateY(${translateY}px) scale(${scale})`;
    },
    delay: index * 25,
    onComplete: removeElement,
  });
};

const defaultNigiriColumnClassName = 'col-12';
const defaultMakiColumnClassName = 'col-md-6 col-lg-4';

const staggerConfig = {
  default: {
    speed: 0.5,
  },
};

interface OwnProps {
  id?: string;
  products: Articles;
  type?: 'list' | React.ComponentType<SushiProps>;
  filters?: 'off' | (keyof Filters)[];
  showLocation?: boolean;
  showCity?: boolean;
  showTitle?: boolean;
  showSupertitle?: boolean;
  showSubtitle?: boolean;
  showBrand?: boolean;
  showCategories?: boolean;
  showTags?: boolean;
  className?: string;
  tagId?: number;
  rowClassName?: string;
  itemClassName?: string;
  itemImageClassName?: string;
  columnClassName?: string;
  itemProps?: Partial<SushiProps>;
  noResultsClassName?: string;
}

type Props = Readonly<OwnProps>;

const FilteredMasonry: React.FC<Props> = ({
  id,
  tagId,
  products,
  type,
  filters,
  showLocation,
  showCity,
  showTitle,
  showSupertitle,
  showSubtitle,
  showBrand,
  showCategories,
  showTags,
  className,
  rowClassName = 'row',
  itemClassName,
  itemImageClassName,
  columnClassName = type === 'list' ? defaultNigiriColumnClassName : defaultMakiColumnClassName,
  noResultsClassName = 'text-info',
  itemProps = {},
}) => {
  const { t } = useTranslation();
  // TODO
  // const eventIdToProductIds = React.useMemo(
  //   () =>
  //     products.reduce<{ [eventId: number]: number[] }>((acc, current) => {
  //       const eventId = current.event_id;
  //       if (eventId !== null) {
  //         return {
  //           ...acc,
  //           [eventId]: [...(acc[eventId] ?? []), current.id],
  //         };
  //       }
  //       return acc;
  //     }, {}),
  //   [products]
  // );
  // const productIdToEventId = React.useMemo(
  //   () =>
  //     products.reduce<{ [productId: number]: number }>((acc, current) => {
  //       const eventId = current.event_id;
  //       if (eventId !== null) {
  //         return {
  //           ...acc,
  //           [current.id]: eventId,
  //         };
  //       }
  //       return acc;
  //     }, {}),
  //   [products]
  // );

  const [filteredSortedProducts, setFilteredSortedProducts] = React.useState(products);
  const flipKey = React.useMemo(() => JSON.stringify(filteredSortedProducts.map((p) => p.id)), [
    filteredSortedProducts,
  ]);

  const SushiElement = type === 'list' ? Nigiri : type ?? Maki;
  const sushiContainerClassName = type === 'list' ? 'nigiri-container' : 'maki-container';

  return (
    <section id={id} className={className} itemScope itemType="http://schema.org/ItemList">
      <Loading>
        <MasonryFilter products={products} onUpdatedProducts={setFilteredSortedProducts} filters={filters} />
      </Loading>
      <Flipper
        className={classNames('masonry', rowClassName)}
        flipKey={flipKey}
        spring="stiff"
        staggerConfig={staggerConfig}
        handleEnterUpdateDelete={handleEnterUpdateDelete}
      >
        {filteredSortedProducts
          // 4888 = ALG und   = DD
          .filter((product) => product.tag_ids.includes(tagId))
          .map((
            product // filtered nach product tag id
          ) => (
            <Flipped flipId={String(product.id)} key={product.id} onAppear={onAppear} onExit={onExit}>
              <article
                id={`article-${product.id}`}
                className={classNames('sushi-container', sushiContainerClassName, columnClassName)}
                itemScope
                itemProp="itemListElement"
                itemType={product.type === 'Product' ? 'http://schema.org/Product' : 'http://schema.org/Event'}
              >
                <SushiElement
                  product={product}
                  showLocation={showLocation}
                  showCity={showCity}
                  showTitle={showTitle}
                  showSupertitle={showSupertitle}
                  showSubtitle={showSubtitle}
                  showBrand={showBrand}
                  showCategories={showCategories}
                  showTags={showTags}
                  className={itemClassName}
                  imageClassName={itemImageClassName}
                  {...itemProps}
                />
              </article>
            </Flipped>
          ))}
      </Flipper>
      {Boolean(products.length) && !filteredSortedProducts.length && (
        <p className={noResultsClassName}>{t(`MASONRY.SEARCH.NO_RESULTS`)}</p>
      )}
    </section>
  );
};

export default React.memo(FilteredMasonry);
